import { useCallback, useEffect } from 'react';

import { Address, Chain } from 'viem';
import { useAccount, useDisconnect } from 'wagmi';

import { useAppKit, useAppKitState } from '@reown/appkit/react';

type Params = { address: Address | undefined; chain?: Chain } | null;

/**
 * Hack for the connection error when the `useWeb3ModalConnectWalletAsync` hook remounts
 * during the connection process. This ensures the callback executes correctly even after remounting.
 */
let callback: ((params: Params) => void) | null = null;

/**
 * @todo Consider refactoring this logic into a global context to handle connection state more effectively
 * and prevent potential issues caused by the current approach.
 */
const useWeb3ModalConnectWalletAsync = () => {
  const { open: openWeb3Modal } = useAppKit();
  const { disconnectAsync } = useDisconnect();

  const { open: isWeb3ModalOpen } = useAppKitState();
  const { address: accountAddress, chain } = useAccount();

  useEffect(() => {
    if (!isWeb3ModalOpen && callback) {
      callback(accountAddress ? { address: accountAddress, chain } : null);
      callback = null;
    }
  }, [accountAddress, chain, isWeb3ModalOpen]);

  return useCallback(async () => {
    await disconnectAsync();

    await openWeb3Modal({
      view: 'Connect',
    });

    return new Promise<Params>((res) => {
      callback = res;
    });
  }, [openWeb3Modal, disconnectAsync]);
};

export default useWeb3ModalConnectWalletAsync;
