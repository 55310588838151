import { WalletType } from '@api';

import { namedErrors, namedWeb3Errors } from '@shared/constants';
import { isApiError } from '@shared/helpers/api';
import displayErrorToast, { ErrorToastArgs } from '@shared/helpers/displayErrorToast';

const getWalletBlockerTemplate = (walletType: WalletType, blockingWallet?: boolean) => {
  if (blockingWallet) {
    return (
      <span>
        To run <b>{walletType}</b>, please turn off <b>{blockingWallet}</b> extension
      </span>
    );
  }

  return (
    <span>
      Please install the <b>{walletType}</b> extension!
    </span>
  );
};

export const WALLET_ERROR_TOAST_ACTION_SCOPE = {
  UNPAID_GAS_FEES: 'UNPAID_GAS_FEES',
  SIGN_MESSAGE_REQUEST: 'SIGN_MESSAGE_REQUEST',
};

export type WalletErrorToastActionScopeName = keyof typeof WALLET_ERROR_TOAST_ACTION_SCOPE;

export type WalletErrorToastActionScope =
  (typeof WALLET_ERROR_TOAST_ACTION_SCOPE)[WalletErrorToastActionScopeName];

interface WalletErrorToastArgs extends Omit<ErrorToastArgs, 'replaceErrorMessageCallback'> {
  unknownExceptionReplaceMessage?: string;
  details?: {
    walletType?: WalletType;
    scope?: WalletErrorToastActionScope;
    customReplaceMessageSet?: Record<string, string>[];
  };
}

const displayWalletErrorToast = ({
  error,
  justReturnHandledMessage,
  unknownExceptionReplaceMessage,
  details,
}: WalletErrorToastArgs) => {
  const replaceErrorMessageCallback: ErrorToastArgs['replaceErrorMessageCallback'] = (
    errorMessage,
  ) => {
    if (errorMessage.includes('null is not an object (evaluating')) {
      return {
        newMessage:
          "Browser probably blocked opening that link.\nPlease allow pop-ups in your browser's settings",
      };
    }

    if (errorMessage === namedErrors.REFLECT_GET_CALLED_ON_NON_OBJECT) {
      if (details?.walletType) {
        return {
          newMessage: getWalletBlockerTemplate(details.walletType),
        };
      }

      return {
        newMessage: `Unsuccessful text replacement: ${errorMessage}`,
      };
    }

    if (
      errorMessage === namedErrors.RESOURCE_UNAVAILABLE ||
      errorMessage.includes(namedErrors.RESOURCE_UNAVAILABLE2)
    ) {
      return {
        newMessage: `Failed to reach your wallet provider/extension. \n Please check it out manually and try again.`,
      };
    }

    if (errorMessage === namedWeb3Errors.PLEASE_CONNECT_TO_PROCEED) {
      return {
        newMessage: errorMessage,
        toastOptions: { type: 'info' },
      };
    }

    if (errorMessage === namedErrors.AN_INTERNAL_ERROR_HAS_OCCURRED) {
      return {
        newMessage: `Looks like you don't have wallets installed in your browser, download any EVM wallet`,
      };
    }

    if (errorMessage === namedWeb3Errors.INVALID_TOKEN_DATA) {
      return { newMessage: `Wallet connection failed` };
    }

    if (errorMessage.toLowerCase().includes('insufficient funds')) {
      return { newMessage: 'You do not have enough native tokens to cover the gas fees for this transaction. Please add funds to your wallet and try again.' };
    }

    if (/user rejected the request|user rejected request/i.test(errorMessage)) {
      if (details?.scope === WALLET_ERROR_TOAST_ACTION_SCOPE.SIGN_MESSAGE_REQUEST) {
        return {
          newMessage: 'You declined the sign message request. Please start the process again',
        };
      }

      if (details?.scope === WALLET_ERROR_TOAST_ACTION_SCOPE.UNPAID_GAS_FEES) {
        return {
          newMessage: 'Transaction failed due to unpaid gas fees. Please try again',
        };
      }

      return { newMessage: `User rejected the request` };
    }

    if (errorMessage.includes('user rejected transaction')) {
      return { newMessage: `User rejected the transaction` };
    }

    if (isApiError(error) && error.data?.type === 'HAS_GITCOIN_SCORE') {
      return {
        newMessage: namedErrors.WALLET_HAS_GITCOIN_SCORE,
      };
    }

    if (errorMessage.includes('transaction underpriced')) {
      return {
        newMessage:
          'This transaction cannot be processed because the fee is too low. To fix this, either try again later, possibly when the network is less busy, or increase the gas fee manually in the transaction settings.',
        toastOptions: {
          autoClose: 30000,
        },
      };
    }

    if (errorMessage.includes('invalid address or ENS name')) {
      return {
        newMessage: 'Invalid address or ENS name',
      };
    }

    // TODO: Improve this util, make it more simpler or come up with a better solution
    for (const customMessage of details?.customReplaceMessageSet || []) {
      const [customErrorMessage, customReplaceMessage] = Object.entries(customMessage)[0];

      if (errorMessage?.toLowerCase().includes(customErrorMessage?.toLowerCase())) {
        return {
          newMessage: customReplaceMessage,
        };
      }
    }

    // For cases where the function throws an unknown exception (e.g. any smart contract transaction exception),
    // and we don't want to show the error to the user, we can show some placeholder instead
    if (unknownExceptionReplaceMessage) {
      console.debug({ error });
      return { newMessage: unknownExceptionReplaceMessage };
    }

    return { newMessage: errorMessage };
  };

  return displayErrorToast({ error, replaceErrorMessageCallback, justReturnHandledMessage });
};

export default displayWalletErrorToast;
